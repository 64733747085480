<template>
  <section id="dashboard">
    <b-row>
      <b-col>
        <h2>Clinician Profile</h2>
      </b-col>
    </b-row>

    <b-row class="content-header">
      <b-col
        md="12"
        class="mb-2 text-right"
      >
        <router-link
          class="btn btn-secondary mr-3"
          type="button"
          :to="{ name: 'view-clinicians' }"
        >
          Back
        </router-link>
      </b-col>
    </b-row>

    <div
      class="container mt-3 bg-white rounded p-5"
    >
      <h2 class="mb-1">
        {{ clinician.name }}
      </h2>
      <h5 class="text-black-50">
        ID: {{ clinician.uuid }}
      </h5>
      <h5 class="text-black-50">
        Email: {{ clinician.email }}
      </h5>
      <h5 class="text-capitalize text-black-50">
        Role: {{ clinician.role.name }}
      </h5>
      <h5 class="text-black-50">
        Phone: {{ clinician.phone ? clinician.phone : 'N/A' }}
      </h5>

      <b-row class="match-height">
        <b-col class="my-2">
          <h3>Assigned Patients</h3>
          <div
            class="table-responsive"
          >
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Reports Count</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="patient in clinician.patients"
                  :key="patient.id"
                >
                  <td>{{ patient.uuid }}</td>
                  <td>{{ patient.first_name }}</td>
                  <td>{{ patient.last_name }}</td>
                  <td>{{ patient.reports_count }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-col>
      </b-row>
    </div>

  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BRow,
    BCol,
  },
  data() {
    return {
      clinicians: [],
      clinician: {},
      cUuid: null,
    }
  },
  mounted() {
    this.getClinician()
  },
  methods: {
    getClinician() {
      useJwt
        .getClinician(this.$route.params.uuid)
        .then(response => {
          this.clinician = response.data.data
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Clinician',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Error fetching clinician',
            },
          })
          this.$router.push({ name: 'view-clinicians' })
        })
    },
  },
}
</script>

<style lang="scss">
// @import '@core/scss/vue/pages/dashboard-ecommerce.scss';
// @import '@core/scss/vue/libs/chart-apex.scss';
</style>
